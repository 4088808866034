/** @format */

* {
	position: relative;
	margin: 0;
	padding: 0;
	line-height: 1;
}

body {
	font-family: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue',
		'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji',
		'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	background-color: var(--bg);
	line-height: 1;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
	margin: 0;
	color: var(--font-color);

	* {
		box-sizing: border-box;
	}
}

body {
	--bg: #222;
	--txt: #fff;
	--lines: #555;
	--gap: 1rem;

	margin: 0;
	padding: 0;
	box-sizing: border-box;
	--gap: 0.75rem;
	--hilite: #6941c6;
	--hilite-dark: #4c2db3;
	--header: #555;

	--btn: var(--hilite);
	--btn-lite: #649fff;

	background: #ccc;
	color: var(--txt);
}

@import './splash';
@import './inputs';
@import './stats';
@import './vote';
@import './mode';
@import './board';

// remove formatting from select
select {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: #111;
	border: 1px solid #333;
	color: #aaa;
	padding: 0.5em 1em;
	font-size: 1rem;
	position: relative;
}

// add arrow to select
.model-space {
	width: 30%;
	display: flex;
	flex-direction: row;
	gap: var(--gap);
	position: relative;
	align-items: center;

	&:before {
		content: 'Model';
		font-size: 0.8em;
		text-transform: uppercase;
	}
	select {
		width: 100%;
	}
	&:after {
		content: '▼';
		position: absolute;
		top: 0.55em;
		right: 0;
		bottom: 0;
		padding: 0 0.5em;
		color: #aaa;
		pointer-events: none;
	}
}
